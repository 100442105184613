import axios from "axios"
import { urls } from "./index.js"

const url = urls().active_url

export const welcome = async () => {
  const response = await axios.get(`${url}/welcome`)
  return response
}

export const login = async (user) => {
  const response = await axios.post(`${url}/login`, user)
  return response
}

export const checkToken = async (token) => {
  const response = await axios.get(`${url}/check-token`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response
}

export const exit = async (token) => {
  const response = await axios.get(`${url}/logout`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response
}

export const updateAgency = async (user) => {
  const response = await axios.put(`${url}/users/${user.id}/update-data`,
    user,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("private_token")}`
      }
    }
  )
  return response
}

export const updatePassword = async (user, data) => {
  const response = await axios.post(`${url}/users/${user.id}/update-password`,
    {
      old_password: data.old_password,
      new_password: data.password
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("private_token")}`
      }
    }
  )
  return response
}

export const sendRegistrationRequest = async (agency) => {
  const response = await axios.post(`${url}/agency/register-request`, agency)
  return response
}

export const fetchAgencyDataFromTbc = async (id) => {
  const response = await axios.get(`${url}/agency/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("private_token")}`
    }
  })
  return response
}

export const recoverPassword = async (email) => {
  const response = await axios.get(`https://tubuencamino.com/reset-agencia-password.php?email=${email}`)
  return response
}
