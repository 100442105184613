{
  "attributes": {
    "Empresa": "Unternehmen",
    "Licencia": "Lizenz",
    "Documento": "Dokument",
    "Email": "E-Mail",
    "Teléfono": "Telefon",
    "País": "Land",
    "Ciudad": "Stadt",
    "Provincia": "Provinz",
    "Código Postal": "Postleitzahl",
    "Dirección": "Adresse",
    "Persona de Contacto": "Kontaktperson",
    "Nombre": "Name",
    "Apellidos": "Nachname",
    "Password": "Passwort"
  },
  "actions": {
    "Enviar": "Senden",
    "Cancelar": "Stornieren",
    "Guardar": "Speichern",
    "Reservar": "Reservieren",
    "Solicitar modificación": "Änderung anfordern",
    "Contactar con soporte": "Kontaktieren Sie den Support",
    "Acceder": "Zugreifen",
    "Solicitar acceso": "Zugang anfordern",
    "Olvidaste tu contraseña?": "Passwort vergessen?",
    "Mantenme logueado en este dispositivo": "Halten Sie mich auf diesem Gerät angemeldet",
    "Iniciar sesión": "Einloggen",
    "previous": "Zurück",
    "next": "Weiter",
    "save-budget": "Budget speichern",
    "reserve": "Reservieren",
    "edit": "Bearbeiten"
  },
  "404": {
    "title": "Diese Seite ist angeln gegangen",
    "text": "Wenn Sie glauben, dass dies nicht korrekt ist, senden Sie uns bitte eine Nachricht an ",
    "back_button": "Zurück zum Dashboard"
  },
  "language": {
    "portuguese": "Portugiesisch",
    "english": "Englisch",
    "spanish": "Spanisch",
    "deutch": "Deutsch",
    "italian": "Italienisch",
    "french": "Französisch"
  },
  "menu": {
    "home": "Startseite",
    "auth": "Authentifizierung",
    "buttons": "Schaltflächen",
    "timelines": "Zeitleisten",
    "budgets": "Budgets",
    "reservations": "Reservierungen",
    "preferences": "Einstellungen",
    "payments": "Zahlungen",
    "login-singup": "Anmelden/Registrieren",
    "404": "404 Seiten",
    "faq": "FAQ",
    "customers": "Kunden",
    "paths": "Pfade",
    "trails": "Wege",
    "prices": "Preise",
    "invoices": "Rechnungen"
  },
  "messages": {
    "Ya tienes acceso a la gestión de tu agencia?": "Haben Sie bereits Zugriff auf das Management Ihrer Agentur?",
    "No tienes acceso a la gestión de tu agencia?": "Haben Sie keinen Zugriff auf das Management Ihrer Agentur?",
    "all": "Alle",
    "new": "Neu",
    "mark_as_read": "Als gelesen markieren",
    "mark_as_unread": "Als ungelesen markieren",
    "answer": "Antworten",
    "answered": "Beantwortet",
    "send-answer": "Antwort senden"
  },
  "notifications": {
    "all": "Alle Benachrichtigungen anzeigen",
    "mark_as_read": "Als gelesen markieren",
    "sentMessage": "Nachricht gesendet",
    "uploadedZip": "Zip-Datei hochgeladen",
    "startedTopic": "Neues Thema gestartet"
  },
  "user": {
    "agency": "Agentur",
    "language": "Sprache ändern",
    "logout": "Ausloggen",
    "profile": "Profil",
    "settings": "Einstellungen",
    "faq": "FAQ",
    "help": "?",
    "helpAndSupport": "Hilfe und Unterstützung",
    "account": "Konto",
    "explore": "Erkunden",
    "data": "Benutzerdaten",
    "password": {
      "old": "Altes Passwort",
      "new": "Neues Passwort",
      "confirm": "Passwort bestätigen",
      "rules": {
        "length": "Das Passwort muss mindestens 8 Zeichen lang sein",
        "minSpecialChars": "Das Passwort muss mindestens ein Sonderzeichen enthalten"
      }
    }
  },
  "support": {
    "send-message": "Kontaktieren Sie den Support",
    "message-sent": "Support-Nachricht gesendet"
  },
  "message": "Nachricht",
  "helpAndSupport": "Hilfe und Unterstützung",
  "search": {
    "placeholder": "Suche..."
  },
  "reservations": {
    "reservation": "Reservierung",
    "customer": "Kunde",
    "rooms": "Zimmer",
    "services": "Dienstleistungen",
    "payments": "Zahlungen",
    "people-to": "Personen",
    "total": "Gesamt",
    "payment-method": "Zahlungsmethode",
    "payd": "Bezahlt",
    "modification-request": "Änderungsanfrage",
    "request-modification": "Änderung anfordern",
    "from-date": "von",
    "to-date": "bis",
    "send-modification": "Änderung senden",
    "modification-sent": "Änderung gesendet",
    "set-customer": "Kunde festlegen",
    "card-payment": "Kartenzahlung",
    "transfer-payment": "Überweisung",
    "no-specified": "Nicht spezifiziert",
    "pay": "Bezahlen",
    "price-per-person": "€/Person"
  },
  "forms": {
    "Datos de Agencia": "Agenturdaten",
    "Datos de Contacto": "Kontaktdaten",
    "reset-password": "Passwort zurücksetzen",
    "invalid": "Überprüfen Sie die markierten Felder, um fortzufahren."
  },
  "trails": {
    "noches": "nächte"
  },
  "prices": {
    "desde": "ab"
  },
  "titles": {
    "options": "Optionen"
  },
  "customers": {
    "new": "Neuer Kunde",
    "edit": "Kunde bearbeiten",
    "updated": "Kunde aktualisiert",
    "error": "Fehler beim Aktualisieren des Kunden"
  },
  "password": {
    "new": {
      "rules": {
        "length": "Das Passwort muss mindestens 8 Zeichen lang sein",
        "minSpecialChars": "Das Passwort muss mindestens ein Sonderzeichen enthalten",
        "differemtFromOld": "Das neue Passwort muss sich vom alten unterscheiden"
      },
      "confirm": {
        "required": "Passwort bestätigen ist erforderlich",
        "match": "Passwörter stimmen überein"
      },
      "old": {
        "required": "Altes Passwort ist erforderlich"
      },
      "new": "Neues Passwort",
      "required": "Passwort ist erforderlich"
    },
    "forgot": {
      "question": "Haben Sie Ihr Passwort vergessen?",
      "email": "E-Mail",
      "action": "Senden",
      "description": "Geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen ein neues Passwort"
    },
    "updated": "Kunde aktualisiert"
  },
  "agency": {
    "data": {
      "updated": "Agentur aktualisiert"
    }
  },
  "info": {
    "loading": "Wird geladen..."
  },
  "customer": {
    "email": "Kunden-E-Mail"
  }
}