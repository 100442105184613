{
  "attributes": {
    "Empresa": "Azienda",
    "Licencia": "Licenza",
    "Documento": "Documento",
    "Email": "Email",
    "Teléfono": "Telefono",
    "País": "Paese",
    "Ciudad": "Città",
    "Provincia": "Provincia",
    "Código Postal": "Codice Postale",
    "Dirección": "Indirizzo",
    "Persona de Contacto": "Persona di contatto",
    "Nombre": "Nome",
    "Apellidos": "Cognome",
    "Password": "Password"
  },
  "actions": {
    "Enviar": "Invia",
    "Cancelar": "Annulla",
    "Guardar": "Salva",
    "Reservar": "Prenota",
    "Solicitar modificación": "Richiedi modifica",
    "Contactar con soporte": "Contatta il supporto",
    "Acceder": "Accedi",
    "Solicitar acceso": "Richiedi accesso",
    "Olvidaste tu contraseña?": "Hai dimenticato la tua password?",
    "Mantenme logueado en este dispositivo": "Mantenimi connesso su questo dispositivo",
    "Iniciar sesión": "Accedi",
    "previous": "Precedente",
    "next": "Successivo",
    "save-budget": "Salva budget",
    "reserve": "Prenota",
    "edit": "Modifica"
  },
  "404": {
    "title": "Questa pagina è andata a pescare",
    "text": "Se pensi che non sia corretto, inviaci un messaggio a ",
    "back_button": "Torna alla dashboard"
  },
  "language": {
    "portuguese": "Portoghese",
    "english": "Inglese",
    "spanish": "Spagnolo",
    "deutch": "Tedesco",
    "italian": "Italiano",
    "french": "Francese"
  },
  "menu": {
    "home": "Home",
    "auth": "Autenticazione",
    "buttons": "Pulsanti",
    "timelines": "Cronologie",
    "budgets": "Preventivi",
    "reservations": "Prenotazioni",
    "preferences": "Preferenze",
    "payments": "Pagamenti",
    "settings": "Impostazioni",
    "login-singup": "Accedi/Iscriviti",
    "404": "404",
    "faq": "FAQ",
    "customers": "Clienti",
    "paths": "Percorsi",
    "trails": "Sentieri",
    "prices": "Prezzi",
    "invoices": "Fatture"
  },
  "messages": {
    "all": "Vedi tutti i messaggi",
    "new": "Nuovi messaggi da {name}",
    "mark_as_read": "Segna come letto",
    "mark_as_unread": "Segna come non letto",
    "answer": "Rispondi",
    "answered": "Risposto",
    "send-answer": "Invia risposta",
    "Ya tienes acceso a la gestión de tu agencia?": "Hai già accesso alla gestione della tua agenzia?",
    "No tienes acceso a la gestión de tu agencia?": "Non hai accesso alla gestione della tua agenzia?"
  },
  "notifications": {
    "all": "Vedi tutte le notifiche",
    "mark_as_read": "Segna come letto",
    "sentMessage": "{name} ti ha inviato un messaggio",
    "uploadedZip": "{name} ha caricato un nuovo file Zip con {type}",
    "startedTopic": "{name} ha avviato un nuovo argomento"
  },
  "user": {
    "agency": "Agenzia",
    "language": "Lingua",
    "logout": "Disconnettersi",
    "profile": "Profilo",
    "settings": "Impostazioni",
    "faq": "FAQ",
    "help": "?",
    "helpAndSupport": "Aiuto e supporto",
    "account": "Account",
    "explore": "Esplora",
    "data": "Dati utente",
    "password": {
      "old": "Vecchia password",
      "new": "Nuova password",
      "confirm": "Conferma password",
      "rules": {
        "length": "La password deve contenere almeno 8 caratteri",
        "minSpecialChars": "La password deve contenere almeno un carattere speciale"
      }
    }
  },
  "support": {
    "send-message": "Contatta il supporto",
    "message-sent": "Messaggio di supporto inviato"
  },
  "message": "Messaggio",
  "helpAndSupport": "Aiuto e supporto",
  "search": {
    "placeholder": "Cerca..."
  },
  "reservations": {
    "reservation": "Prenotazione",
    "customer": "Cliente",
    "rooms": "Camere",
    "services": "Servizi",
    "payments": "Pagamenti",
    "people-to": "persone",
    "total": "Totale",
    "payment-method": "Metodo di pagamento",
    "payd": "Pagato",
    "modification-request": "Richiesta di modifica",
    "request-modification": "Richiedi modifica",
    "from-date": "da",
    "to-date": "a",
    "send-modification": "Invia modifica",
    "modification-sent": "Modifica inviata",
    "set-customer": "Imposta cliente",
    "card-payment": "Pagamento con carta",
    "transfer-payment": "Pagamento con bonifico",
    "no-specified": "Non specificato",
    "pay": "Paga",
    "price-per-person": "€/persona"
  },
  "forms": {
    "Datos de Agencia": "Dati dell'agenzia",
    "Datos de Contacto": "Dati di contatto",
    "reset-password": "Reimposta password",
    "invalid": "Controlla i campi contrassegnati per continuare."
  },
  "trails": {
    "noches": "notti"
  },
  "prices": {
    "desde": "da"
  },
  "titles": {
    "options": "Opzioni"
  },
  "customers": {
    "new": "Nuovo cliente",
    "edit": "Modifica cliente",
    "updated": "Cliente aggiornato",
    "error": "Errore nell'aggiornamento del cliente"
  },
  "password": {
    "new": {
      "rules": {
        "length": "La password deve contenere almeno 8 caratteri",
        "minSpecialChars": "La password deve contenere almeno un carattere speciale",
        "differentFromOld": "La nuova password deve essere diversa dalla vecchia password"
      },
      "confirm": {
        "required": "La conferma della password è richiesta",
        "match": "Le password corrispondono"
      },
      "old": {
        "required": "La vecchia password è richiesta"
      },
      "new": "Nuova password",
      "required": "La password è richiesta"
    },
    "forgot": {
      "question": "Password dimenticata?",
      "email": "Email",
      "action": "Invia",
      "description": "Inserisci il tuo indirizzo email e ti invieremo une nuova password"
    },
    "updated": "Password aggiornata"
  },
  "agency": {
    "data": {
      "updated": "Dati dell'agenzia aggiornati"
    }
  },
  "info": {
    "loading": "Caricamento..."
  },
  "customer": {
    "email": "Email del cliente"
  }
}