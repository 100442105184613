<script setup lang="ts">
import ProfileDropdown from "./dropdowns/ProfileDropdown.vue"
import NotificationDropdown from "./dropdowns/NotificationDropdown.vue"
import ModalComponent from "../../ModalComponent.vue"
import {useI18n} from "vue-i18n"
import {ref, defineAsyncComponent} from "vue"
const LanguageSelector = defineAsyncComponent(() => import("../../LanguageSelector.vue"))
const SendSupportMessage = defineAsyncComponent(() => import("./SendSupporMessage.vue"))


defineProps({
  isMobile: {type: Boolean, default: false}
})


const {t} = useI18n()
const selectedModal = ref("")
const mensaje = ref("")
const selectingLang = ref(false)

const showLangSelection = () => {
  selectingLang.value = !selectingLang.value
}
const showModal = (modalName: string) => {
  return selectedModal.value === modalName
}

function toggleModal(modalName: string) {
  if (selectedModal.value === modalName) {
    selectedModal.value = ""
  } else {
    selectedModal.value = modalName
  }
}

</script>
<template>
  <div class="app-navbar-actions">
    <div class="block rounded md w-fit h-fit p-1 bg-sky-100" v-if="selectingLang" id="languages-selection-container">
      <LanguageSelector />
    </div>
    <button @click.prevent="showLangSelection" class="bg-yellow-400 rounded-full flex mx-2 items-center content-center align-middle p-1.5">
      <VaIcon name="language" />
    </button>
    <button
      v-if="!isMobile"
      preset="secondary"
      color="#ffffff"
      class="rounded-full px-2.5 py-1.5 flex items-center content-center align-middle w-fit h-fit bg-yellow-400"
      @click.prevent="toggleModal('support')"
    >
      <span class="flex items-center content-center align-middle w-full h-full text-gray-900 rounded-full">{{ t("user.help") }}</span>
    </button>
   <!--  <NotificationDropdown class="app-navbar-actions__item" /> -->
    <ProfileDropdown class="app-navbar-actions__item app-navbar-actions__item--profile mr-1" />
    <ModalComponent :show="showModal('support')" max-width="full" @close="toggleModal('')">
      <SendSupportMessage @close="toggleModal('')" />
    </ModalComponent>
  </div>
</template>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  .va-dropdown__anchor {
    color: var(--va-primary);
    fill: var(--va-primary);
  }

  &__item {
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    svg {
      height: 20px;
    }

    &--profile {
      display: flex;
      justify-content: center;
    }

    .va-dropdown-content {
      background-color: var(--va-white);
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .fa-github {
    color: var(--va-on-background-primary);
  }
}
.language-selection-container {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(100%); /* Inicialmente oculto fuera de la pantalla */
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background-color: #f0f9ff; /* Ajusta el color de fondo si es necesario */
  z-index: 1;
}

.language-selection-container-enter-active {
  transform: translateY(-50%) translateX(0); /* Desliza hacia dentro */
  opacity: 1; /* Aparece */
}

.language-selection-container-leave-active {
  transform: translateY(-50%) translateX(100%); /* Desliza hacia fuera */
  opacity: 0; /* Desaparece */
}

</style>
