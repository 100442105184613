<template>

    <img :src="logo" alt="TBC Logo" :width="props.width" />

</template>
<script lang="ts" setup>
import { onBeforeMount,ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const props = defineProps({
  width: {
    type: Number,
    default: 200
  }
})
const logos = [
  '/img/300x240-TBC.png',
  '/img/tbc-logo-blanco.png',
]

const logo = ref(logos[0])

onBeforeMount(() => {
  if(route.name !== 'login' && route.name !== 'signup') {
    logo.value = logos[1];
  }
})
</script>

<style scoped>

</style>
