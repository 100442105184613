import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router"

import AuthLayout from "../layouts/AuthLayout.vue"
import AppLayout from "../layouts/AppLayout.vue"
const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: {name: "home"}
  },
  {
    name: "admin",
    path: "/",
    component: AppLayout,
    children: [
      {
        name: "home",
        path: "home",
        component: () => import("../pages/admin/Home.vue")
      },
      {
        name: "budgets",
        path: "budgets",
        component: () => import("../pages/admin/dashboard/IndexBudgets.vue")
      },
      {
        name: "reservations",
        path: "reservations",
        component: () => import("../pages/admin/dashboard/IndexReservations.vue")
      },
      {
        name: "settings",
        path: "settings",
        component: () => import("../pages/settings/Settings.vue")
      },
      {
        name: "preferences",
        path: "preferences",
        component: () => import("../pages/preferences/Preferences.vue")
      },
      {
        name: "customers",
        path: "customers",
        component: () => import("../pages/customers/CustomersPage.vue")
      },
      {
        name: "prices",
        path: "prices",
        component: () => import("../pages/prices/Index.vue")
      },
      {
        name: "invoices",
        path: "invoices",
        component: () => import("../pages/invoices/Index.vue")
      },
      {
        name: "trails",
        path: "trails",
        component: () => import("../pages/trails/Index.vue")
      },
      {
        name: "reserve",
        path: "reserve/:pathId/:trailId",
        component: () => import("../pages/reservations/widgets/ReservationForm.vue")
      },
      {
        name: "faq",
        path: "/faq",
        component: () => import("../pages/faq/FaqPage.vue")
      }
    ]
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("../pages/auth/Login.vue")
      },
      {
        name: "signup",
        path: "signup",
        component: () => import("../pages/auth/Signup.vue")
      },
      {
        name: "recover-password",
        path: "recover-password",
        component: () => import("../pages/auth/RecoverPassword.vue")
      },
      {
        name: "recover-password-email",
        path: "recover-password-email",
        component: () => import("../pages/auth/CheckTheEmail.vue")
      },
      {
        path: "",
        redirect: {name: "login"}
      },
      {
        path: "/logout",
        name: "logout",
        redirect: {name: "login"}
      }
    ]
  },
  {
    name: "404",
    path: "/404",
    component: () => import("../pages/404.vue")
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return {el: to.hash, behavior: "smooth"}
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes
})


router.beforeEach(async (to, from, next) => {
  localStorage.setItem("lastVisitedRoute", from.fullPath)
  next()
})
export default router
