<template>
  <RouterView />
</template>

<style lang="scss">
@import "scss/main.scss";

#app {
  font-family: "Inter", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>

<script setup>
  import { onBeforeMount, onMounted } from "vue";
  import { useAuthStore } from "./stores/auth";
  import { useGlobalStore } from "./stores/global-store";
  import { useI18n } from 'vue-i18n';
  import { useRouter }  from 'vue-router';

  const router = useRouter();
  const globalStore = useGlobalStore();
  const { locale } = useI18n();
  onBeforeMount(() => {
    useAuthStore().language = window.navigator.language || "en";
    locale.value = useAuthStore().language;
  });

  onMounted(() => {
    useAuthStore().initAuth()
    router.afterEach(() => {
      globalStore.isSidebarMinimized = true;
    });
  })
</script>
